body {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


.myFont {
  font-family: 'Lobster', cursive;
}
.titleHero {
  font-size: 7rem;
}


@media (max-width: 640px) {
  .titleHero {
    font-size: 6rem;
  }
}

