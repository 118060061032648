.image-container.open {
    cursor: zoom-out;
    z-index: 100;
}



.shade {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    opacity: 0;
    background: rgba(0, 0, 0, 0.9);
}

.image-container.open .shade {
    pointer-events: auto;
    opacity: 1;
}

.image-container img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 49;
    object-fit: cover;
}


.image-container.open img {
    position: fixed;
    width: auto;
    height: 80vh;
    max-width: 100%;
    margin: auto;
    z-index: 51;
}


@media (max-width: 640px) {
    .image-container {
        height: 80%;
    }

    .image-container.open img {
        height: 40vh;
    }


}